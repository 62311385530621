/*
	Skin
	
*/

/*
	Esse skin usa defauts das env vars
*/
const { PUBLIC_AUTHORITY, PUBLIC_CLIENT_ID } = import.meta.env

/*
	Favicon 
	usar import favicon from 'caminho/pro/favicon.ico'
	se não estiver na pasta public
*/
const favicon 		= 'favicon.ico'

/*
	Configs gerais para essa skin
*/
const title 		= 'Sucessiva'
const authority 	= PUBLIC_AUTHORITY
const client_id 	= PUBLIC_CLIENT_ID

/*
	Theme
	IMPORTANTE: Isso será um dynamic import
	Usar path relativo ao ./src/plugins/skins e usar extensão
*/
const cssTheme 		= 'theme.css'

/*
	Exportando
*/

export default {
	title,
	authority,
	client_id,
	cssTheme,
	favicon
}