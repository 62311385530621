import appendToHead from "./appendToHead"

/*
	Adiciona uma folha de estilos pelo seu href
*/
export default href => {
	let element = document.createElement('link')
	element.rel = "stylesheet"
	element.href = href
	appendToHead(element)
}