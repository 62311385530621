/*
	Configuração do nosso oidc
*/

import skins from "../../../plugins/skins"

export default async () => {

	let { authority, client_id } = await skins()

	return {
		authority,
		client_id,
		redirect_uri: window.location.origin+'/signin-callback-oidc',
		response_type: 'code',
		scope: 'openid profile roles cartorio-api oab phone offline_access email',
		post_login_route: '/signin-callback-oidc',
		post_logout_redirect_uri: window.location.origin,

		/*
			Monitoramento de sessão
		*/
		monitorSession: true,
		silent_redirect_uri: window.location.origin+"/silent-renew.html"

	}

}
