/*
	Gerenciamento de Skins

	Podemos mudar tanto a aparência
	quanto algumas funcionalidades do App
	
	Interpreta a env var PUBLIC_SKIN para
	tudo funcionar automaticamente tanto
	em dev quanto em builds e pipelines

*/

import appendFavicon from '../global/appendFavicon'
import appendStyle from '../global/appendStyle'
import sucessiva from './public/sucessiva'

/*
	Resolve o url público para um determinado asset
	levando em consideração de onde esse módulo foi carregado
*/

const resolveSkinAssetURL = (skin, path ) => 
	import.meta.env.PROD
		? `/assets/skins/${skin}/${path}`
		: new URL(`./public/${skin}/${path}`, import.meta.url).href

let skinsPromise = new Promise( async (resolve ) => {

	/*
		Skin padrão
	*/
	let skin = sucessiva

	/*
		Skin selecionada
	*/
	let { PUBLIC_SKIN = 'sucessiva' } = import.meta.env

	/*
		Se temos skins 
	*/
	if(PUBLIC_SKIN !== 'sucessiva'){

		/*
			Importante para o Vite manter o import relativo e espeficicar o tipo de arquivo
		*/
		let skinOverride = (await import(`./public/${PUBLIC_SKIN}/index.js`)).default || {}

		/*
			CSS da sucessiva é aplicado por padrão
			Aqui nós sobrescrevemos
		*/
		if(skinOverride.cssTheme) 
			appendStyle(resolveSkinAssetURL(PUBLIC_SKIN, skinOverride.cssTheme))
			
		/*
			Favicon da sucessiva é aplicado por padrão
			Aqui nós sobrescrevemos
		*/
		if(skinOverride.favicon)
			appendFavicon(resolveSkinAssetURL(PUBLIC_SKIN, skinOverride.favicon))
		
		/*
			Override do skin padrão
		*/
		skin = { ...skin, ...skinOverride }

	}

	/*
		Aplicando título
	*/
	document.title = skin.title

	/*
		Retornando a skin
	*/
	resolve(skin)

})

export default () => skinsPromise