import appendToHead from "./appendToHead"

/*
	Adiciona um favicon pelo seu href
*/
export default href => {
	let element = document.createElement('link')
	element.rel = "icon"
	element.href = href
	appendToHead(element)
}